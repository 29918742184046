import { Button } from "~/components/Button";
import { cn } from "~/utils/cn";
import { useBoardConfig } from "../../../contexts/BoardConfigContext";
import { Bell, CopyPlus } from "lucide-react";
import { NewCountBadge } from "~/components/NewCountBadge";

const className =
  "bg-jet-black rounded-2xl flex flex-col gap-2 items-center text-base [font-family:'Inter-Medium',Helvetica] font-medium leading-4 relative";

export const BoardActions: React.FC<{
  board: { _id: string; name: string; newAlertsCount: number };
  expanded: boolean;
}> = ({ board, expanded }) => {
  const { config, setAddColumn, setShowAlerts } = useBoardConfig(board._id);
  return (
    <div className={cn("grid gap-2 w-full", expanded ? "grid-cols-2" : "grid-cols-1")}>
      <Button
        className={cn(
          className,
          config.showAlerts && "bg-charcoal",
          expanded ? "h-[96px]" : "h-[48px]"
        )}
        variant={"transparent"}
        onClick={() => setShowAlerts(!config.showAlerts)}
      >
        <Bell className={cn("text-primary", expanded ? "w-6 h-6" : "w-4 h-4")} />
        {expanded && <div>Alerts</div>}
        {expanded ? (
          <div className="absolute top-3 right-3">
            <NewCountBadge count={board.newAlertsCount} />
          </div>
        ) : (
          <div className="absolute top-2.5 right-2.5">
            <div className="rounded-full bg-red-500 p-1" />
          </div>
        )}
      </Button>
      <Button
        className={cn(
          className,
          config.showAddColumn && "bg-charcoal",
          expanded ? "h-[96px]" : "h-[48px]"
        )}
        variant={"transparent"}
        onClick={() => setAddColumn(!config.showAddColumn)}
      >
        <CopyPlus className={cn("text-primary", expanded ? "w-6 h-6" : "w-4 h-4")} />
        {expanded && <div>Add Column</div>}
      </Button>
    </div>
  );
};
