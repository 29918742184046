import { Link } from "@remix-run/react";
import { CircleUserIcon, Ellipsis, X } from "lucide-react";
import { SideMenuToggle } from "~/components/SideMenuToggleIcon";
import { cn } from "~/utils/cn";

export const Account = ({
  email,
  setMenuOpen,
  setExpanded,
  expanded,
  isMobile,
}: {
  email: string;
  setMenuOpen?: (open: boolean) => void;
  setExpanded: () => void;
  expanded: boolean;
  isMobile?: boolean | undefined;
}) => {
  return (
    <div
      className={cn(
        "w-full flex gap-2 items-center justify-between bg-jet-black rounded-2xl p-4",
        expanded ? "flex-row" : "flex-col-reverse"
      )}
    >
      <CircleUserIcon className="w-6 h-6 rounded-full text-white" />
      {expanded && (
        <div className="flex-grow">
          <div
            className={cn(
              "text-[#fdfdfd] text-base font-semibold font-['Inter'] leading-none text-ellipsis truncate",
              isMobile ? "w-[calc(100vw-150px)]" : "w-[107px]"
            )}
          >
            {email}
          </div>
        </div>
      )}
      <Link
        to="/boards/settings"
        onClick={() => {
          setMenuOpen ? setMenuOpen(false) : "";
        }}
      >
        <div className="w-8 h-8 rounded-lg flex justify-center items-center cursor-pointer bg-charcoal hover:bg-black">
          <Ellipsis className="text-primary w-5" />
        </div>
      </Link>
      {isMobile && setMenuOpen ? (
        <div
          onClick={() => setMenuOpen(false)}
          className="w-8 h-8 rounded-lg flex justify-center items-center cursor-pointer bg-charcoal hover:bg-black"
        >
          <X className="w-4 h-4 text-gray-300" />
        </div>
      ) : (
        <div
          onClick={setExpanded}
          className="w-8 h-8 rounded-lg flex justify-center items-center cursor-pointer bg-charcoal hover:bg-black"
        >
          <SideMenuToggle />
        </div>
      )}
    </div>
  );
};
